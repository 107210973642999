.animation,
.animation_layer {
  height: 100vh;
  /* height: 1000px; */
}

.animation {
  display: block;
  position: relative;
  z-index: 10;
  background-color: rgb(15 23 42);
}

.animation_layer {
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
}
.animation_layer.parallax {
  position: fixed;
}

#artback {
  background-image: url(/public/assets/img/sky.webp);
  height: 800px;
}

#mountain {
  background-image: url(/public/assets/img/mountains2.webp);
  height: 100vh;
}

#logoland {
  width: 215px;
  position: absolute;
  left: 72%;
  top: 15%;
  z-index: 30;
}

.text-block {
  left: 10%;
  top: -10%;
  font-size: 7rem;
}

.description-block {
  left: 10%;
  position: absolute;
  top: 12%;
}

.aboutus-parallax {
  min-height: 100%;
}

@media (max-width: 1440px) {
  #logoland {
    width: 180px;
    left: 78%;
    top: 12%;
  }

  .text-block {
    left: 15%;
    top: -15%;
    font-size: 5rem;
  }

  .description-block {
    left: 5%;
    top: 20%;
    width: 45%;
  }
}

@media (max-width: 1024px) {
  #logoland {
    left: 77%;
    width: 165px;
  }

  .text-block {
    font-size: 4.5rem;
  }

  .description-block {
    left: 5%;
    top: 10%;
    width: 40%;
  }
}

@media (max-width: 768px) {
  #logoland {
    width: 120px;
    left: 55%;
    top: 6%;
  }

  .text-block {
    left: -10%;
    top: -28%;
    font-size: 3rem;
  }

  .description-block {
    left: 5%;
    top: 15%;
    margin: 0 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .description-block p {
    line-height: 1.2;
    font-size: 0.82rem;
  }

  .award-parallax {
    min-height: 145vh;
  }

  .judge-parallax {
    top: 10% !important;
  }
}

@media (max-width: 768px) and (max-height: 768px) {
  #logoland {
    width: 100px !important;
    left: 58% !important;
  }
}

@media (max-width: 425px) {
  #logoland {
    /* left: 52%; */
  }

  .text-block {
    /* left: -15%; */
  }

  .description-block {
    left: 5%;
    top: 30%;
  }

  .judge-parallax {
    top: 15% !important;
  }
}

@media (max-width: 375px) {
  .text-block {
    /* left: -15%; */
  }
}
